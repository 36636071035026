html {
  scroll-behavior: smooth;
}

.header-video{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  z-index: 0;
  filter:blur(4px);
}

.header-overlay{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.2);
  z-index:1;
}

.header-content{
  z-index:2;
}

.play-btn{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.round-btn {
	box-sizing: border-box;
	display:block;
	width:80px;
	height:80px;
	padding-top: 14px;
	padding-left: 8px;
	line-height: 20px;
	border: 6px solid #fff;
	border-radius: 50%;
	color:#f5f5f5;
	text-align:center;
	text-decoration:none;
	background-color: rgba(0,0,0,0.5);
	font-size:20px;
	font-weight:bold;
	transition: all 0.3s ease;
}
.round-btn:hover {
	background-color: rgba(0,0,0,0.8);
	box-shadow: 0px 0px 10px rgba(111,150,255,1);
	text-shadow: 0px 0px 10px rgba(111,150,255,1);
}

.poster {
  object-fit: cover;
  width:100%;
  height:100%;
}


#video-estimcad{
  margin-bottom: 5em;
  border: solid 3px #337ab7;
  width: 100%;
  .vjs-big-play-button {
    // top:50%;
    // left:50%;
    // transform: translate(-50%,-50%);
    background-color: #337ab7;
  }
}


.slider {
  position: relative;
  border: 3px solid #337ab7;
  
  margin:auto;
  margin-bottom:3em;
  img {
    max-width: 100%;
  }

  @media (min-width: 1200px) {
    width:80%;
  }
}

.slider-nav {
  /*display: flex;*/
  overflow: hidden;
  position: relative;
  .btn {
    color: white;
    background-color: #337ab7;
    padding: 1rem;
    position: absolute;
    height: 100%;
    width: 7%;
    transition: background-color 0.2s;
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .next {
    right: 0;
  }
  .prev {
    left: 0;
  }
}

.prev:hover,
.next:hover {
  background-color: #3d8ed4;
  cursor: pointer;
}
.prev:active,
.next:active {
  background-color: #61acee;
}
.pages {
  max-width: 80%;
  min-height: 60px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 0;
    text-align: center;
    li {
      line-height: 1.1rem;
      height: 32px;
      font-size: 1rem;
      display: inline-block;
      padding: 0.4em;
      user-select: none;
      transition: background-color 0.2s;
      color: rgb(126, 126, 126);
      &:hover {
        background-color: #e9e9e9;
        cursor: pointer;
      }
      &:active {
        background-color: #cacaca;
      }
      &.active {
        color: black;
        font-weight: bold;
        transform: scale(1.1);
      }
    }
  }
}

.img-container {
  position: relative;
  .slide {
    position: relative;
  }
  img {
    display: block;
    user-select: none;
  }
}
.tip {
  position: absolute;
  background-color: rgba(182, 215, 243, 0.9);
  padding: 1rem;
  border-radius: 1.2rem;
  border: 3px solid #3d8ed4;
  font-weight: bold;
  div:not(:first-child) {
    margin-top: 1rem;
  }

}

.hidden {
  display: none;
}


/* utilities */
.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }



.flex-equal > * {
  -ms-flex: 1;
  -webkit-box-flex: 1;
  flex: 1;
}
@media (min-width: 768px) {
  .flex-md-equal > * {
    -ms-flex: 1;
    -webkit-box-flex: 1;
    flex: 1;
  }
}

.overflow-hidden { overflow: hidden; }